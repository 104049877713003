import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../../../components/shared/button"
import Pattern from "../../../components/shared/pattern"
import Socials from "../../../components/shared/socials"
import Photo from "./photo"
import Copy from "./copy"
import { useArticleData } from "./data"
import Icon from "../../../components/shared/icon"

const Box = styled.section`
  height: calc(90vh - 7rem);
  position: relative;

  .hero-bkg {
    width: 60%;
    right: 0;
    top: 0;
    height: 45%;
    position: absolute;

    .xn-pattern {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 766px) {
    height: auto;

    .hero-bkg {
      height: 39%;
    }
  }
`

const Wrap = () => {
  const data = useArticleData()
  const uid = data[0].node.uid
  const node = data[0].node.data
  const firstPublished = node.published
  const lastPublished = data[0].first_publication_date

  return (
    <Box>
      <Photo>
        <Link to={"/articles/" + uid} className="photo-link">
          <img src={node.cover.fluid.src} alt={node.title.text} />
          <Pattern type="strip" />
        </Link>
        <Socials slug={"articles/" + uid} title={node.title.text} />
      </Photo>
      <Copy>
        <h1>
          <Link to={"/articles/" + uid}>{node.title.text}</Link>
        </h1>
        <time>
          <div>
            <Icon title="clock" />
          </div>
          <span>{firstPublished ? firstPublished : lastPublished}</span>
        </time>
        <p>{node.summary.text}</p>
        <Button to={"/articles/" + uid} theme="dark" label="Read" />
      </Copy>
      <div className="hero-bkg">
        <Pattern type="dot" size="4" />
      </div>
    </Box>
  )
}

export default Wrap
