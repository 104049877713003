export const createSlug = text => {
  if (text.length) {
    return text
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-")
  } else {
    return false
  }
}
