import React, { useState } from "react"
import styled from "styled-components"
import { createSlug } from "../../../utilities/createSlug"

const Box = styled.div`
  border-bottom: 1px solid #d5dae6;
  width: 45%;
  margin: 0 auto;
  margin-bottom: 3.5rem;

  div {
    &.hide {
      display: none;
    }
  }

  button {
    display: block;
    padding: 1.125rem 0.75rem;
    position: relative;
    transition: color 0.3s;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.black};

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      height: 3px;
      transition: transform 0.2s;
      width: 100%;
      left: 0;
      background-color: ${props => props.theme.mint600};
      transform: scale(0, 1);
    }

    &.is-active,
    &:hover {
      color: ${props => props.theme.mint600};
      &:after {
        transform: scale(1, 1);
      }
    }
  }

  .scroll-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 766px) {
    width: calc(100% - 3rem);
    overflow: scroll;
    display: block;
    padding-top: 2rem;

    button {
      padding-bottom: 1.5rem;
    }
    .scroll-wrap {
      width: 200%;
      display: block;

      div {
        display: inline-block;
        padding-right: 1.5rem;

        &.hide {
          display: none;
        }
      }
    }
  }
`

const Filter = ({ filter }) => {
  let categories = [
    {
      label: "Articles",
      value: "Insights",
    },
    {
      label: "Markets",
      value: "Market",
    },
    {
      label: "News",
      value: "Press Release",
    },
    // {
    //   label: "Stories",
    //   value: "User Stories",
    // },
    {
      label: "Events",
      value: "Events",
    },
  ]
  let filters = []

  categories.forEach(category => {
    if (category.value) {
      filters.push(createSlug(category.value))
    }
  })

  const [active, setActive] = useState(-1)

  const filterArticles = (index, slug) => {
    setActive(index)
    filter(slug)
  }

  return (
    <Box>
      <div className="scroll-wrap">
        <div>
          <button
            className={active === -1 ? "is-active" : ""}
            onClick={() => filterArticles(-1, "all")}
          >
            Recent
          </button>
        </div>

        {filters &&
          filters.map((item, index) => {
            return (
              <div key={index}>
                <button
                  className={active === index ? "is-active" : ""}
                  onClick={() => filterArticles(index, item)}
                >
                  {categories[index].label}
                </button>
              </div>
            )
          })}
      </div>
    </Box>
  )
}

export default Filter
