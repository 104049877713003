import React, { useState } from "react"
import styled from "styled-components"
import Nav from "./filter"
import Article from "./article"
import Grid from "../../../components/shared/articles-grid"
import { useArticlesData } from "./data"
import { createSlug } from "../../../utilities/createSlug"

const Box = styled.section`
  padding-bottom: 10rem;
  padding-top: 1rem;
`

const Wrap = () => {
  const data = useArticlesData()
  const [activeFilter, setActiveFilter] = useState("all")

  const showArticles = filter => {
    setActiveFilter(filter)
  }

  return (
    <Box>
      <Nav filter={showArticles} />
      <Grid>
        {data &&
          data.map((item, index) => {
            let node = item.node.data
            let firstPublished = node.published
            let lastPublished = node.first_publication_date
            let category = ""
            if (node.type && node.type.length) {
              category = createSlug(node.type)
            }

            return (
              <Article
                className={
                  category === activeFilter || activeFilter === "all"
                    ? "show"
                    : "hide"
                }
                summary={node.summary.text}
                key={index}
                photo={node.cover.fluid.src}
                title={node.title.text}
                slug={item.node.uid}
                date={firstPublished ? firstPublished : lastPublished}
              />
            )
          })}
      </Grid>
    </Box>
  )
}

export default Wrap
