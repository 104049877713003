import React from "react"
import Meta from "../utilities/seo"
import Hero from "../templates/learn/hero/wrap"
import Articles from "../templates/learn/articles/wrap"

const Index = () => {
  return (
    <>
      <Meta
        title="Learn"
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1610370876/hero-image-01_vebjbx.png",
          height: "998",
          width: "665",
        }}
        description="Learn about XENO new product features, investment solutions and financial market insights."
      />
      <Hero />
      <Articles />
    </>
  )
}

export default Index
