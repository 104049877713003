import styled from "styled-components"

const Photo = styled.figure`
  z-index: 2;
  width: 60%;
  height: 70%;
  top: 10%;
  background-color: #e3e3e3;
  position: absolute;
  z-index: 1;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .photo-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  .xn-socials {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding-top: 0;
    z-index: 2;

    a {
      margin-right: 0;
      padding: 1.25rem;

      &:hover {
        path {
          fill: ${props => props.theme.black};
        }
      }
    }
  }

  @media screen and (max-width: 766px) {
    width: 100%;
    height: 32vh;
    position: relative;
  }
`
export default Photo
