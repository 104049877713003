import styled from "styled-components"

const Copy = styled.div`
  padding: 3rem;
  right: 0;
  position: absolute;
  bottom: 4rem;
  width: 50%;
  background-color: ${props => props.theme.mint};
  z-index: 1;

  h1 {
    font-size: 2.5rem;
    padding-bottom: 1.25rem;
    line-height: 3.125rem;
    letter-spacing: -0.5px;
  }

  time {
    text-transform: uppercase;
    font-size: 0.75rem;
    display: inline-flex;
    font-weight: 500;

    span {
      padding-left: 0.625rem;
      position: relative;
      top: 1px;
    }

    div {
      height: 1rem;
      width: 1rem;
      display: inline-block;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  p {
    padding: 2rem 0 3rem;
    line-height: 1.7rem;
  }

  @media screen and (max-width: 766px) {
    position: static;
    width: 100%;

    h1 {
      font-size: 2rem;
      line-height: 2.7rem;
    }
  }
`

export default Copy
