import { useStaticQuery, graphql } from "gatsby"

export const useArticleData = () => {
  const data = useStaticQuery(
    graphql`
      query heroArticleQuery {
        allPrismicArticle(
          filter: { id: { ne: "7e040f53-1d89-5061-b16f-7f8d84e8d54a" } }
          sort: { fields: first_publication_date, order: DESC }
          limit: 1
        ) {
          edges {
            node {
              data {
                type
                title {
                  text
                }
                summary {
                  text
                }
                cover {
                  fluid {
                    src
                  }
                }
                published(fromNow: true)
              }
              uid
              first_publication_date(fromNow: false)
            }
          }
        }
      }
    `
  )
  return data.allPrismicArticle.edges
}
